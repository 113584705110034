/* ============================================================
 * Plugin Core Init
 * For DEMO purposes only. Extract what you need.
 * ============================================================ */
$(document).ready(function() {
    'use strict';
    //Intialize Slider
    var slider = new Swiper('#hero', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 1,
        parallax: true,
        speed: 1000,
    });

    //Intialize Testamonials
    var testimonials_slider = new Swiper('#testimonials_slider', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        parallax: true,
        speed: 1000
    });

    // Initialize Search
    $('[data-pages="search"]').search({
        // Bind elements that are included inside search overlay
        searchField: '#overlay-search',
        closeButton: '.overlay-close',
        suggestions: '#overlay-suggestions',
        brand: '.brand',
        // Callback that will be run when you hit ENTER button on search box
        onSearchSubmit: function(searchString) {
        },
        // Callback that will be run whenever you enter a key into search box.
        // Perform any live search here.
        onKeyEnter: function(searchString) {
            var searchField = $('#overlay-search');
            var searchResults = $('.search-results');

            /*
                Do AJAX call here to get search results
                and update DOM and use the following block
                'searchResults.find('.result-name').each(function() {...}'
                inside the AJAX callback to update the DOM
            */

            // Timeout is used for DEMO purpose only to simulate an AJAX call
            clearTimeout($.data(this, 'timer'));
            searchResults.fadeOut("fast"); // hide previously returned results until server returns new results
            var wait = setTimeout(function() {

                searchResults.find('.result-name').each(function() {
                    if (searchField.val().length != 0) {
                        $(this).html(searchField.val());
                        searchResults.fadeIn("fast"); // reveal updated results
                    }
                });
            }, 500);
            $(this).data('timer', wait);

        }
    });



        'use strict';

       // if($(window).width() >= 769)







});


$(document).ready(function(){
    $("#toogleshow").click(function(){
        $(".mobile-light").addClass('show-mobile');
    });

    $('#closeshow').click(function () {
        $(".mobile-light").removeClass('show-mobile');
    });


});

window.onscroll = function() {scrollFunction()};
function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        $('.pre-header').addClass("scrollUp");
        $('.mynav').removeClass("add-padding-nav")
    } else {
        $('.pre-header').removeClass("scrollUp");
        $('.mynav').addClass("add-padding-nav");
    }
}


function openNav() {

    $("#header").addClass('show-mobile');
}

function closeNav() {
    $("#header").removeClass('show-mobile');
}

